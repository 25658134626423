import React, { useState, useEffect } from "react";

export default function useClientServerWorkaround() {
  const [isClient, setIsClient] = useState(false);
  const key = isClient ? "client" : "server";

  useEffect(() => {
    setIsClient(true);
  }, []);

  return key;
}

export function useUpdateOpacityOnScrollIntoView(ref, offset) {
  useEffect(() => {
    let listener = () => {
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        if ((top + offset) >= 0 && (top - offset) <= window.innerHeight) {
          ref.current.style.opacity = 1;
        }
      }
    }

    //invoke immediately in case it is already in view
    listener();
    window.addEventListener("scroll", listener);

    return () => {
      if (listener) {
        window.removeEventListener("scroll", listener);
      }
    }
  }, [ref, ref.current, offset]);
}
