import React, { useEffect, useState, useRef } from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { Formik } from 'formik';
import axios from 'axios';
import loadjs from 'loadjs';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden';
import { useLocation } from '@reach/router';
import { FaCheckSquare } from "react-icons/fa";
import styles from './contactUsForm.module.css';
import SectionHeader from '../SectionHeader/sectionHeader';
import { useUpdateOpacityOnScrollIntoView } from '../helperHooks';

const imagesQuery = graphql`
  query {
    backgroundPic: file(
      relativePath: { eq: "websitelogosandcolorscheme/contactFormBackground.png" }
    ) {
      childImageSharp {
        fixed(cropFocus: CENTER, height: 770) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
 
const ContactUsForm = ({ style }) => {
    const picQuery = useStaticQuery(imagesQuery);
    const [isSuccess, setIsSuccess] = useState(false);
    const [recaptchaLoaded, setRecapthcaLoaded] = useState(false);
    const textRef = useRef(null);
    const location = useLocation();

    useUpdateOpacityOnScrollIntoView(textRef, 125);

    useEffect(() => {
      loadjs(`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_CLIENT_ID}`, { 
        returnPromise: true,
        numRetries: 3
      })
        .then(() => { setRecapthcaLoaded(true); })
        .catch(() => { setRecapthcaLoaded(false); })
    }, []);

    let styleOveride = {};

    if (style) {
      styleOveride = style;
    }

    let sectionHeaderSubtitle = [];
    if (location.search && location.search.indexOf('contactToBookEsthetician') !== -1) {
      sectionHeaderSubtitle.push("To Book Esthetician Services");
    }


   return (
   <div className={styles.picAndFormContainer} style={styleOveride}>
     <Img
        fixed={picQuery.backgroundPic.childImageSharp.fixed}
        imgStyle={{ objectFit: "contain" }}
        className={styles.background}
     />
    <div className={styles.container}>
      <div className={styles.textContainer} ref={textRef}>
        <SectionHeader title={"Contact Us"} extraTitles={sectionHeaderSubtitle} className={styles.headerText} />
        <p className={styles.text}>
          1008 Cherry Valley Blvd. Suite 100, Calimesa, CA 92320
          <br />
          info@skinsolutionsaesthetics.com
          <br />
          Tel: (909) 795-2050 / Fax: (951) 468-0536
        </p>
        <p className={styles.text}>
          OPENING HOURS
          <br />
          Tues - Fri: 9am - 5:30pm
          <br />
          Saturdays by appointment only
        </p>
      </div>
      <div style={{width: '100%'}}> {
        isSuccess 
        ? 
          <div className={styles.successContainer}>
            <p className={styles.text}>
              Your message was successfully sent! 
              <br />
              Thanks for reaching out. Someone will be in contact with you shortly.
            </p>
            <div className={styles.checkMark}>
              {/* <FaCheckSquare /> */}
              <svg className={styles.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle className={styles.checkmarkCircle} cx="26" cy="26" r="25" fill="none"/>
                <path className={styles.checkmarkCheck} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg>
            </div>
          </div>
        : 
     (<Formik
       initialValues={{ email: '', subject: '', text: ''}}
       validate={values => {
         const errors = {};
         if (!values.email) {
           errors.email = 'Required';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
         return errors;
       }}
       onSubmit={(values, { setSubmitting, setErrors }) => {
        window.grecaptcha.ready(function() {
          window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_CLIENT_ID, {action: 'submit'}).then(function(token) {
            axios.post('/.netlify/functions/sendMail', { values, token }).then(function (response) {
                setSubmitting(false);
                setIsSuccess(true);
            }).catch(function (error) {
                setSubmitting(false);
                setErrors({
                    global: 'Error requesting the service'
                });
            });
          });
        });
       }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
         <form onSubmit={handleSubmit} className={styles.form}>
           <div className={styles.column}>
            <TextField 
              type="text" 
              name="name" 
              onChange={handleChange}
              onBlur={handleBlur} 
              label="Name" 
              className={styles.input} 
              value={values.name}
              />
            <TextField 
              error={errors.email && touched.email && values.email !== ''} 
              type="text" 
              name="email" 
              onChange={handleChange}
              onBlur={handleBlur} 
              label="Email" 
              helperText={errors.email && touched.email && values.email !== '' ? 'Please enter a valid email.' : undefined} 
              className={styles.input} 
              value={values.email}
              />
            <TextField
              type="text"
              name="subject"
              onChange={handleChange}
              onBlur={handleBlur}
              label="Subject"
              value={values.subject}
              className={styles.input}
            />
           </div>
           <TextField
             type="text"
             name="text"
             onChange={handleChange}
             onBlur={handleBlur}
             label="Message"
             value={values.text}
             className={styles.textArea}
             multiline
             variant="outlined"
             rows={7}
           />
           <div className={styles.submitContainer}>
            <Button type="submit" disabled={isSubmitting || !recaptchaLoaded} className={styles.submitButton}>
              Submit
            </Button>
            <Hidden lgUp><div className={styles.legalText}>I understand and agree that any information submitted will be forwarded to our office by email and not via a secure messaging system. This form should not be used to transmit private health information, and we disclaim all warranties with respect to the privacy and confidentiality of any information submitted through this form.</div></Hidden>
           </div>
           {errors.global ? errors.global : null}
         </form>
       )}
     </Formik>)
}
   </div>
   <Hidden mdDown><div className={styles.legalText}>I understand and agree that any information submitted will be forwarded to our office by email and not via a secure messaging system. This form should not be used to transmit private health information, and we disclaim all warranties with respect to the privacy and confidentiality of any information submitted through this form.</div></Hidden>

   </div>
   </div>
 )
       };

 export default ContactUsForm;